import React, { useRef, useState } from "react"
import { Formik } from "formik"

import { submitForm, FormatFormData, handleSignupResponse, dateCheck } from "../services/index"

import {
  Col2,
  Content,
  FieldSet,
  HeaderWithLogo,
  LCard,
} from "../style/containers"
import LearLogo from "../assets/images/logos/lear-corporation-logo.jpg"
import LearCorp from "../assets/images/lear-corp.jpeg"
import { LargeImage } from "../components/starterContent"

import Button from "../components/button"
import Layout from "../components/layout"
import Signature from "../components/Signature"
import StarterContent from "../components/starterContent"
import { initialValues, validationSchema } from "../components/LearFoamPlant"
import Form from "../components/form/FormHelper"
import { FormikInput, Error } from "../components/form"
import PersonalDetailsMinimum from "../components/PersonalDetails/minimum"

const IntroductionForm = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const submit = async (values, actions) => {
    if (signatureDrawn) {
      const formatData = {
        ...values,
        personal_details: {
          ...values.personal_details,
          date_of_birth:  
          dateCheck(values.personal_details.date_of_birth)
          }
        }
      const formData = await FormatFormData(formatData, sigCanvas)
      let res = await submitForm(formData, "/lear")
      handleSignupResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }

  return (
    <Layout emailNoCheck={true}>
      <LCard>
        <FieldSet>
          <HeaderWithLogo>
            <h1>Lear Induction (Foam Plant)</h1>
            <img src={LearLogo} alt="Lear Logo" />
          </HeaderWithLogo>
          <h2>Our Details</h2>
          <Col2>
            <div>
              <p>
                Lear Corporation,
                <br />
                Unit 11 Phoenix Way,
                <br /> Rainton Bridge Industrial Estate,
                <br /> Houghton le Spring,
                <br /> Tyne and Wear, DH4 5SA
              </p>
            </div>
            <div>
              <LargeImage src={LearCorp} alt="Lear Corporation site" />
            </div>
          </Col2>
          <StarterContent />
          <Content>
            <hr />
          </Content>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Col2>
                  <FormikInput
                    name="consultant_name"
                    type="text"
                    label="Consultant name"
                  />
                </Col2>
                <h2>Personal Details</h2>
                <PersonalDetailsMinimum />
                <Signature
                  signatureDrawn={signatureDrawn}
                  setSignatureDrawn={setSignatureDrawn}
                  sigCanvas={sigCanvas}
                />
                {errorText
                  ? errorText.map(e => (
                      <Error style={{ marginTop: "1rem" }} key={e}>
                        {e}
                      </Error>
                    ))
                  : null}
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  name="new-starter"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </FieldSet>
      </LCard>
    </Layout>
  )
}

export default IntroductionForm
