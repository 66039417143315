import * as Yup from "yup"
import moment from "moment"
import { standardRequiredText, phoneRegex } from "../../constants"
import { parseDateString } from "../../utils/form-data"

export const validationSchema = Yup.object().shape({
  consultant_name: Yup.string().required(standardRequiredText),
  personal_details: Yup.object().shape({
    first_name: Yup.string().required(standardRequiredText),
    surname: Yup.string().required(standardRequiredText),
    date_of_birth: Yup.date()
      .transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      )
      .required(standardRequiredText),
    email: Yup.string()
      .email(
        "Must be a valid email address in the format 'example@example.com'"
      )
      .required(standardRequiredText),
    mobile: Yup.string()
      .matches(phoneRegex, "Must be a valid phone number")
      .required(standardRequiredText),
    landline: Yup.string().matches(
      phoneRegex,
      "Must be a valid phone number"
    ),
    address_1: Yup.string().required(standardRequiredText),
    address_2: Yup.string(),
    address_3: Yup.string(),
    postcode: Yup.string().required(standardRequiredText),
    address_town: Yup.string().required(standardRequiredText),
    country: Yup.string().required(standardRequiredText),
  }),
  method_of_transport: Yup.array()
    .min(1)
    .required(standardRequiredText),
})
