export const initialValues = {
  consultant_name: "",
  personal_details: {
    first_name: "",
    surname: "",
    date_of_birth: "",
    email: "",
    mobile: "",
    landline: "",
    address_1: "",
    address_2: "",
    address_3: "",
    postcode: "",
    address_town: "",
    country: "",
  },
  method_of_transport: [],
}
